div#navbar {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.c2 span {
	color: black;
	font-family: "Akira";
	background: white;
	font-size: 12.5px;
	padding: 0.5em 1.75em;
	cursor: url(../assets/cursor/pointer.cur);
}

.c2 a {
	padding: 0.5em 1.75em;
	color: white;
	font-family: "Akira";
	cursor: url(../assets/cursor/link.cur), 3rem;
	font-size: 12.5px;
	text-decoration: none;
	position: relative;
	z-index: 3;
}

.c2 {
	display: flex;
	align-items: center;
	flex-direction: row;
	white-space: nowrap;
	justify-content: center;
}
.c1 {
	line-height: 20px;
}
.c1 h2 {
	color: transparent;
	background: var(--gradient);
	background-clip: text;
	-webkit-background-clip: text;
}

/* Footer */
.logo img {
	filter: brightness(1) contrast(2.5);
	width: 7rem;
}
.fotext h1 {
	text-align: left;
}
div#Footer {
	padding: 3em 10em;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	padding-bottom: 1em;
}
.logo {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
}

#Footer p {
	width: 42%;
}

.links {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	list-style: none;
}
.links ul a {
	color: black;
	background-color: white;
	font-family: "Akira";
	text-decoration: none;
	cursor: url(../assets/cursor/link.cur), 3rem;
	margin-right: 1em;
	padding: 0.5em 0.75em;
	font-size: 12px;
}
.copy {
	margin: 0;
	margin-top: 2em;
}
.follow {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	text-align: center;
}

.sl {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	column-gap: 0.5em;
	margin: 0.25em;
	font-size: 20px;
}
.sl a {
	color: white;
	text-decoration: none;
	cursor: url(../assets/cursor/link.cur), 3rem;
}
.horitext {
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 15%;
}

.horitext p {
	text-align: end;
}
#navbar svg {
	display: none;
}

@media only screen and (max-width: 1024px) {
	.horitext p {
		text-align: center;
	}
	.c2 a {
		font-size: 140%;
		margin: 5% 0;
	}
	.menu {
		display: block;
	}
	.gayab {
		display: none;
	}
	.c1 {
		display: block;
	}
	#navbar svg {
		display: block;
		color: white;
		font-size: 20px;
	}
	#navbar {
		position: relative;
	}
	.c2 {
		flex-direction: column;
		position: absolute;
	}
	.c2 {
		position: absolute;
		background-color: #494949;
		transform: translate(0%, 70%);
		padding: 2em 1rem;
		z-index: 323244231;
		font-size: 10px;
		row-gap: 0px;
		border-radius: 2rem;
	}

	.c2 span {
		margin: 1em;
		background: transparent;
		color: white;
	}
	.links ul {
		flex-direction: column;
		display: flex;
		row-gap: 1em;
	}
	#Footer p {
		width: 100%;
		text-align: center;
	}
	p.copy {
		white-space: nowrap;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}
}
