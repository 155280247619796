.firstlay {
	background: url(../assets/ROhS8Wt.png);
	overflow: hidden;
}
.firstlay,
.secondlay,
.thirdlay,
.gradient-overlay,
.fifthlay,
.pdtlay,
.termslay {
	padding: 2em 4em;
}

/* Firstlay stuff */
#firstlay {
	position: relative;
}
#content {
	z-index: 1;
	position: relative;
}
#content h1 span {
	color: transparent;
	background: var(--gradient);
	background-clip: text;
}

#content p {
	width: 40%;
}

.buttons a:nth-child(1) {
	background: var(--gradient);
	font-family: "Akira";
	padding: 0.5em 0.75em;
	column-gap: 0.5em;
	cursor: url(../assets/cursor/link.cur), 3rem;
}
.buttons a {
	display: flex;
	align-items: center;
	flex-direction: row;
	text-decoration: none;
}

.buttons a:nth-child(2) {
	color: white;
	font-family: "Akira";
}

.buttons {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: flex-start;
	gap: 2em;
}
div#content {
	padding: 7% 5% 14%;
}
.icon img {
	width: 100%;
}

.icon {
	width: 5rem;
}

.card {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 0 1em;
	justify-content: center;
	flex-wrap: wrap;
}

.cards {
	display: flex;
	flex-wrap: wrap;
}

.contento h1 {
	font-size: 20px;
	margin: 0;
	text-align: center;
}

.contento p {
	margin: 6px 0;
	font-size: 14px;
	text-align: center;
}

.contento {
	width: 13rem;
}

.secondlay {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

div#About {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.thirdlay p {
	font-size: 15px;
	text-align: center;
	padding: 0% 10%;
}

.peoplecards img {
	width: 10rem;
}

.peoplecards {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 250px;
	height: 250px;
}

.container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	column-gap: 1em;
	row-gap: 1.25em;
	margin: 1em 0;
	flex-direction: row;
	flex-wrap: wrap;
}
.peoplecards h1,
.peoplecards p {
	margin: 0;
}
.pplimg::after {
	content: "";
	display: block;
	height: 100%;
	width: 100%;
	top: 0;
	box-shadow: -2px -21px 2rem 2rem black inset;
	position: absolute;
	left: 0;
}

.pplimg {
	position: relative;
}

.peoplecards p {
	font-size: 11px;
}
.peoplecards:nth-child(4) {
	background: var(--gradient);
}
.wanted {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	row-gap: 0.75em;
}

.wanted h1 {
	color: black;
}

.wanted p {
	color: black;
	font-size: 15px;
}

.wanted a {
	font-family: "Akira";
	background: var(--gradient);
	color: transparent;
	font-size: 14px;
	background-clip: text;
}
.wanted a svg {
	color: white;
}
.tadg {
	background: black;
	white-space: nowrap;
	padding: 4% 8%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.stat h2 {
	font-size: 60px;
	background: var(--gradient);
	background-clip: text;
	margin: 0;
	color: transparent;
}

.liontent {
	text-align: center;
	display: flex;
	align-items: center;
	column-gap: 5em;
	justify-content: center;
	margin: 2em 0;
}

.gradient-overlay {
	background: rgba(0, 0, 0, 0.88);
}

.fourthlay {
	--tw-gradient-from: #f7f0ac;
	--tw-gradient-to: #f0acf7;
	--tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, hsla(54, 82%, 82%, 0));
}

.fourthlay {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-image: linear-gradient(to right, var(--tw-gradient-stops));
	padding: 0;
}

.gradient-overlay {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.88);
}
.fourthlay p {
	margin: 0;
}

.fourthlay img {
	width: 4rem;
}

div#products {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 5%;
}
.fifthlay h1 {
	margin: 0;
}

.pdtimg img {
	width: 100%;
	object-fit: cover;
	height: 100%;
	transition: all ease 0.5s;
}

.pdtimg {
	width: 15rem;
	height: 20rem;
	position: relative;
	transition: all ease-in-out 0.2s;
	cursor: pointer;
}

.pdtimg:hover {
	transform: translateY(00.25em);
}

.category {
	background: var(--gradient);
	position: absolute;
	right: 0.75em;
	z-index: 1;
	top: 0.25em;
}

.category p {
	color: black;
	margin: 0;
	font-family: "Akira";
	padding: 0.5rem 0.75rem;
	font-size: 10px;
}
.randompdt {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.randomProducts {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	flex-wrap: wrap;
	column-gap: 3em;
	row-gap: 3em;
	margin: 2em 0;
}

.randompdt h2 {
	margin: 4% 0;
	font-size: 15px;
	margin-top: 8%;
}

.randompdt p {
	margin: 0;
}
.fifthlay a {
	background: var(--gradient);
	color: black;
	font-family: "Akira";
	text-decoration: none;
	padding: 0.75rem 2rem;
}
.pdtimg {
	overflow: hidden;
}
.waves {
	background: url(https://cdn.discordapp.com/attachments/919139461751767040/1198572602646605914/wave.388abec.png?ex=65bf64b8&is=65acefb8&hm=a481a362df5623e5cb317de6e454a1226f4393555ea2f2e2047e6ed9b828c5f8&);
	width: 100%;
	height: 100%;
	position: absolute;
	background-size: 40rem;
	background-repeat: no-repeat;
	top: -10%;
	left: 0%;
	z-index: 0;
	zoom: 200%;
}
.pcards {
	display: flex;
	flex-wrap: wrap;
	padding: 1em 2em;
	column-gap: 3em;
	row-gap: 2em;
	align-items: center;
	justify-content: center;
}
.pcard {
	cursor: url(../assets/cursor/link.cur), 3rem;
}
.det {
	position: absolute;
	bottom: 0;
	color: rgb(227, 221, 221);
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	width: 100%;
	z-index: 2121;
	transition: all ease-in-out 0.5s;
	height: 100%;
	filter: opacity(0);
	padding-bottom: 3em;
}
.det h4,
.det p {
	margin: 0.15em;
	font-family: "Akira";
	filter: drop-shadow(0 0 1rem black);
}

.smth::after {
	content: "";
	width: 100%;
	height: 25%;
	display: block;
	position: absolute;
	bottom: 0;
	background: linear-gradient(0deg, #0000008a, transparent);
	z-index: 2;
	transition: all ease-in-out 0.175s;
}
.smth:hover::after {
	height: 100%;
}
.det:hover {
	filter: opacity(1);
}
.det p {
	font-family: "Poppins", sans-serif;
}
.cattags button {
	padding: 0.75em 1.25em;
	font-family: "Akira";
	border-radius: 5rem;
	margin: 0em 0.75em 0em;
	color: white;
	background: black;
	transition: all ease 0.5s;
	border: 2px solid white;
	cursor: url(../assets/cursor/link.cur), 3rem;
}

.cattags {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	margin: 2em;
}

button.active {
	background: var(--gradient);
	color: black;
}
.pcard {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.cat {
	margin: 0;
}
.descs {
	margin-bottom: 2em;
}
.det button {
	font-family: "Akira";
	padding: 2% 3%;
	font-size: 10px;
	margin: 0.5rem;
}
.desc a {
	font-family: "Akira";
	color: black;
	background: var(--gradient);
	padding: 2% 3%;
	text-decoration: none;
}
.desc a span {
	font-family: "Poppins", sans-serif;
	font-weight: 800;
	font-size: 20px;
}
.desc svg {
	color: white;
	border: 3px solid white;
	width: 1rem;
	height: 1rem;
	padding: 1%;
	border-radius: 50%;
	cursor: url(../assets/cursor/link.cur), 3rem;
	position: absolute;
	right: 1em;
	top: 1em;
	filter: opacity(0.75);
}
.desc.visible {
	display: flex;
}

.desc {
	width: 50%;
	padding-left: 5rem;
	background: linear-gradient(135deg, rgb(70, 69, 69), transparent);
	display: none;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	align-items: flex-start;
	cursor: url(../assets/cursor/pointer.cur), 3rem;
}
.termslay h1 {
	font-size: 20px;
	text-align: center;
}

.termslay p {
	font-size: 15px;
	text-align: center;
}

div#Title,
cattags,
#terms {
	padding: 3% 20%;
}
.termslay {
	background: url(https://cdn.discordapp.com/attachments/1080886691067338815/1198212235038838824/nduksi52zrs41.png?ex=65be151a&is=65aba01a&hm=4aba89a165d6c381a7db4108e1835075c6d222c935ec510b9f3cb36c63965e1a&);
	background-size: cover;
	box-shadow: 0rem -8rem 32em 35rem #00000095 inset;
	background-repeat: no-repeat;
}

div#terms {
	backdrop-filter: blur(0.5em);
}

div#Title {
	filter: drop-shadow(0px 0px 1em black);
}
.pdtlay {
	background: url(../assets/actual_1427472506-640x360.jpg);
	background-size: cover;
	box-shadow: 0rem -8rem 32em 35rem #00000095 inset;
	background-repeat: no-repeat;
}
.tadg a {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	column-gap: 0.75em;
}
svg.svg-inline--fa.fa-angle-down {
	font-size: 20px;
}
.cards {
	justify-content: center;
}
@media only screen and (max-width: 1024px) {
	.pdtlay {
		width: auto;
	}
	.cattags {
		margin: 10% 0;
		flex-wrap: wrap;
		gap: 1em;
		width: 100%;
		justify-content: space-between;
	}
	.desc svg {
		top: 0;
		right: 0;
	}
	.pcard.visible {
		flex-direction: column;
		display: flex;
		text-align: center;
		position: relative;
		justify-content: center;
		align-items: center;
		gap: 1em;
	}
	.terms {
		margin: 2em 0;
	}
	.desc a {
		align-self: center;
	}
	.cat {
		align-self: center;
	}
	div#Title {
		margin: 2em 0;
	}
	.waves {
		rotate: 0deg;
	}
	.liontent {
		flex-direction: column;
	}
	#content p {
		width: 100%;
	}

	div#content {
		margin: 0;
		padding: 0;
	}

	#content h1 {
		font-size: 25px;
		margin-top: 50%;
	}
	.buttons {
		flex-direction: column;
	}
	.secondlay {
		position: relative;
		z-index: 9798979789797;
	}

	.icon {
		width: 3rem;
	}

	.contento h1 {
		font-size: 70%;
	}
	div#terms,
	div#Title {
		padding: 0;
	}
	.contento p {
		font-size: 10px;
	}
	.thirdlay p {
		font-size: 10px;
		width: 100%;
	}
}

/* Loading.css */
.loading-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.loading-inner {
	width: 20px;
	height: 20px;
	border: 4px solid #3498db;
	border-radius: 50%;
	animation: grow 1s ease-in-out infinite;
}

@keyframes grow {
	0% {
		width: 20px;
		height: 20px;
		opacity: 0.7;
	}
	50% {
		width: 40px;
		height: 40px;
		opacity: 0.4;
	}
	100% {
		width: 60px;
		height: 60px;
		opacity: 0;
	}
}
