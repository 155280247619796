@font-face {
	font-family: Akira;
	src: url(./assets/Akira.otf);
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600;700;800&display=swap");
:root {
	--gradient: linear-gradient(90deg, rgb(239, 246, 146) 0%, rgb(135, 191, 231) 50%, rgb(249, 169, 245) 100%);
}
:focus-visible,
:focus-within {
	stroke: none;
	outline: none;
}

a,
button {
	cursor: url(./assets/cursor/link.cur), 3rem;
}
ul {
	padding: 0;
}
h1,
h2 {
	font-family: "Akira";
	color: white;
}
p {
	color: rgb(169, 166, 166);
	font-family: "Poppins";
	font-weight: 600;
}
body {
	background-color: black;
	overflow-x: hidden;
	-webkit-tap-highlight-color: transparent;
	user-select: none;
	-webkit-user-drag: none;
}
::-webkit-scrollbar,
::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
	display: none;
	width: 0;
}
.App {
	cursor: url(./assets/cursor/pointer.cur), 3rem;
}
@media only screen and (max-width: 1024px) {
	.horitext {
		flex-direction: column;
		text-align: center;
	}

	.horitext p {
		width: 85%;
		text-align: center;
	}

	.cards {
		justify-content: center;
	}

	.c1 {
		display: none;
	}

	.c2 {
		width: 100%;
		text-align: center;
	}
	div#content {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin: 2em;
		flex-direction: column;
	}

	#content p {
		margin: 1em 0 4em;
	}
	.pdtimg {
		min-width: 10rem;
		max-width: 10rem;
		min-height: 15rem;
		max-height: 15rem;
	}
	.horitext p {
		min-width: 90%;
		text-align: center;
	}
	.desc.visible {
		width: 95%;
		padding: 5%;
	}
	.smth img {
		width: 100%;
		height: 100%;
	}
	.desc.visible {
		background: transparent;
	}
	.pcard.visible {
		background: linear-gradient(135deg, rgb(70, 69, 69), transparent);
		padding: 10%;
	}
	.c2 a {
		font-size: 140%;
		margin: 5% 0;
	}
}

@media only screen and (max-width: 600px) {
}
